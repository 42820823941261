// Import just what we need

// import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';
import './index.scss';

window.onload = () => {
    window.openSection = (id) => {
        if (window.location.href.indexOf("impressum") > -1 || window.location.href.indexOf("datenschutz") > -1) {
            window.location.href = `${window.location.origin}/${id}`;
        } else {
            if (id == '') {
                window.scrollToTop();
            } else {
                var element = document.getElementById(id);
                element.scrollIntoView(true)
            }
        }

    }
    window.scrollToTop = () => window.scrollTo(0, 0);
    if (window.location.href.indexOf("datenschutz") > -1) {
        document.getElementById('home').style = "display: none";
        document.getElementById('impressum').style = "display: none";
        document.getElementById('datenschutz').style = "display: block";
    } else if (window.location.href.indexOf("impressum") > -1) {
        document.getElementById('home').style = "display: none";
        document.getElementById('datenschutz').style = "display: none";
        document.getElementById('impressum').style = "display: block";
    } else if (window.location.href.indexOf("kontakt") > -1) {
        document.getElementById('home').style = "display: block";
        window.openSection("kontakt");
    } else {
        document.getElementById('home').style = "display: block";
    }
    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            if (window.scrollY > 250) {
                document.getElementsByClassName('back-to-top')[0].classList.add('show');
            }
            document.getElementsByClassName('navbar')[0].classList.add('has-shadow');
        } else {
            document.getElementsByClassName('back-to-top')[0].classList.remove('show');
            document.getElementsByClassName('navbar')[0].classList.remove('has-shadow');
        }
    })
}